import React from "react";
import {Helmet} from "react-helmet";
import {StaticImage} from "gatsby-plugin-image";

import Egg from "../../components/common/Egg";
import Tel from "../../components/common/Tel";
import Email from "../../components/common/Email";

import * as classes from "./index.module.scss";

export default function ContactUsPage() {

    return (
        <section className={classes.container}>
            <Helmet>
                <title>Контакты</title>
            </Helmet>
            <div>
                <h1>Контакты</h1>
                <div className={classes.companyInfoContainer}>
                    <h2>ООО «РУССЭЛЛ»</h2>
                    <p>ОГРН 1145260003899</p>
                </div>
                <p className={classes.address}>
                    603136, г. Нижний Новгород, <br />ул. Академика Сахарова, д. 4, оф. 419
                </p>
                <p className={classes.contacts}>
                    <Tel className={classes.tel} />{" "}
                    <Email className={classes.email} />
                </p>
            </div>
            <Egg className={classes.egg}>
                <StaticImage
                    src="img/main.jpg"
                    alt="Медицинские изделия для диагностики рака и коронавируса"
                    layout="constrained"
                    width={693}
                    height={536}
                    sizes="(max-width: 767px) 421px, 693px"
                    outputPixelDensities={[0.6075, 1, 1.215, 2]} />
            </Egg>
        </section>
    );

}
